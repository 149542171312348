import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import userStore from './stores/userStore';
import Login from './components/Pages/Login/Login';
import EmptyScreen from './components/Pages/EmptyScreen/EmptyScreen';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import Statistics from './components/Pages/Statistics/Statistics';
import Management from './components/Pages/Management/Management';
import Orders from './components/Pages/Orders/Orders';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import SetCredentials from './components/Pages/SetCredentials/SetCredentials';
import './App.css';
import ProductManagementTable from './components/Pages/ProductManagementTable/ProductManagementTable';

const App = observer(() => {
    const [menuVisible, setMenuVisible] = useState(true);
    const appRef = useRef(null);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    if (userStore.isLoading) {
        return <div>Загрузка...</div>; 
    }

    return (
        <div ref={appRef} className="App">
            <Router>
                {userStore.isAuthenticated && menuVisible && (
                    <div className="tileMenu">
                        <Link to="/CdDCwZhQdzVOB6Ex01kuwQO" className="tile" onClick={toggleMenu}>Статистика</Link>
                        {userStore.useCloudStorage ? (
                            <Link to="/uROnGCSdu5hzy1mpRPMnneE" className="tile" onClick={toggleMenu}>Товары</Link>
                        ):(
                            <Link to="/ur3fTPWfv0MHfA4vDOYzKCw" className="tile" onClick={toggleMenu}>Товары</Link>
                        )}                    
                        <Link to="/6vkD9rk8smiHfVP37J2nwhr" className="tile" onClick={toggleMenu}>Заказы</Link>
                        <Link to="/cVRp6RRbkTKSnCanSfwR4QM" className="tile" onClick={toggleMenu}>Установить логин и пароль</Link>
                    </div>
                )}
                {!menuVisible && (
                    <div className="menuToggle">
                        <button className="bbutton" onClick={toggleMenu}>Меню</button>
                    </div>
                )}
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/cVRp6RRbkTKSnCanSfwR4QM" element={<PrivateRoute><SetCredentials /></PrivateRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><EmptyScreen /></PrivateRoute>} />
                    <Route path="/CdDCwZhQdzVOB6Ex01kuwQO" element={<PrivateRoute><Statistics /></PrivateRoute>} />
                    <Route path="/uROnGCSdu5hzy1mpRPMnneE" element={<PrivateRoute><Management appRef={appRef} /></PrivateRoute>} />
                    <Route path="/6vkD9rk8smiHfVP37J2nwhr" element={<PrivateRoute><Orders /></PrivateRoute>} />
                    <Route path="/ur3fTPWfv0MHfA4vDOYzKCw" element={<PrivateRoute><ProductManagementTable /></PrivateRoute>} />
                    <Route path="/" element={<Navigate to={userStore.isAuthenticated ? "/dashboard" : "/login"} replace />} />
                </Routes>
            </Router>
        </div>
    );
});

export default App;