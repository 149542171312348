import React from 'react';
import './ProductManagementTable.scss';
const Table = ({ children }) => {
  return <table className="table">{children}</table>;
};

const TableHeader = ({ children }) => {
  return <thead className="table-header">{children}</thead>;
};

const TableBody = ({ children }) => {
  return <tbody className="table-body">{children}</tbody>;
};

const TableRow = ({ className, onClick, children }) => {
  return (
    <tr className={`table-row ${className}`} onClick={onClick}>
      {children}
    </tr>
  );
};

const TableCell = ({ className, children }) => {
  return <td className={`table-cell ${className}`}>{children}</td>;
};

export { Table, TableHeader, TableBody, TableRow, TableCell };
