import React from 'react';
import './TextArea.scss';

const TextArea = ({ value, onChange, onBlur, readOnly }) => {
  return (
    <textarea
      className={`textarea ${readOnly ? 'textarea-readonly' : ''}`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      readOnly={readOnly}
    />
  );
};

export default TextArea;
