import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import userStore from '../../../stores/userStore';
import { useNavigate } from 'react-router-dom';
import './SetCredentials.scss';

const SetCredentials = observer(() => {
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [login, setLogin] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await userStore.setCredentials(login, password);
      navigate('/dashboard');
    } catch (error) {
      console.error('Setting credentials failed', error);
      setError('Ошибка при установке учетных данных');
    }
  };

  return (
    <div className='set-credentials-container'>
      <h2 className='header'>Установить логин и пароль</h2>
      {error && <p className="error">{error}</p>}
      <input
        type="text"
        value={login}
        onChange={(e) => setLogin(e.target.value)}
        placeholder="Логин"
        className="input"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Пароль"
        className="input"
      />
      <button onClick={handleSubmit} className="button">
        Установить учетные данные
      </button>
    </div>
  );
});

export default SetCredentials;