import { makeAutoObservable } from "mobx";
import $api from '../http';
import { authenticateWithInitData, setCredentials } from '../http/telegramAuth';
class UserStore {
    user = null;
    accessToken = null;
    refreshToken = null;
    isLoading = true;
    useCloudStorage = true;

    constructor() {
        makeAutoObservable(this);
        this.initialize();
    }

    async initialize() {
        try {
            this.useCloudStorage = window.Telegram.WebApp.initData !== '';
            await this.checkAuthentication();
        } catch (error) {
          console.error('Ошибка при инициализации приложения', error);
        } finally {
          this.isLoading = false;
        }
      }
    
      async authenticateWithInitData(login, password) {
        try {
          const response = await authenticateWithInitData({
            InitData: this.useCloudStorage ? window.Telegram.WebApp.initData : null,
            Login: login,
            Password: password
          });
          if (response.success) {
            const user = this.useCloudStorage ? window.Telegram.WebApp.initDataUnsafe?.user : response.id;
            this.setTokens(response.accessToken, response.refreshToken);
            this.setUser(user);
          } else {
            throw new Error('Ошибка аутентификации');
          }
        } catch (error) {
          console.error('Authentication failed:', error);
          throw error;
        }
      }
    
      async setCredentials(login, password) {
        try {
            const response = await setCredentials(
                this.useCloudStorage ? window.Telegram.WebApp.initData : null,
                login,
                password
              );
            if (response.success) {
                const user = this.useCloudStorage ? window.Telegram.WebApp.initDataUnsafe?.user : response.id;
                this.setTokens(response.accessToken, response.refreshToken);
                this.setUser(user);
            } else {
                throw new Error('Ошибка установки учетных данных');
            }
        } catch (error) {
          console.error('Setting credentials failed:', error);
          throw error;
        }
      }
    setUser(user) {
        this.user = user;
    }

     setTokens(accessToken, refreshToken) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        $api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        if (this.useCloudStorage) {
            window.Telegram.WebApp.CloudStorage.setItem('accessToken', accessToken);
            window.Telegram.WebApp.CloudStorage.setItem('refreshToken', refreshToken);
          } else {
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
          }
        
    }
    clearTokens() {
        this.accessToken = null;
        this.refreshToken = null;
        delete $api.defaults.headers.common['Authorization'];
        if (this.useCloudStorage) {
            window.Telegram.WebApp.CloudStorage.removeItem('accessToken');
            window.Telegram.WebApp.CloudStorage.removeItem('refreshToken');
          } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
          } 
    }

    get isAuthenticated() {
        return !!this.user;
    }

    async checkAuthentication() {
        const accessToken = this.useCloudStorage
        ? await window.Telegram.WebApp.CloudStorage.getItem('accessToken')
        : localStorage.getItem('accessToken');
        if (accessToken) {
            try {
                await this.authenticateWithInitData(null, null);
            } catch (error) {
                console.error('Token verification failed', error);
                this.clearTokens();
            }
        } else {
        this.clearUser();
        }
      }


    clearUser() {
        this.user = null;
    }

    async refreshTokens() {
        try {
            const response = await $api.post('/user/refresh', {}, {
                headers: {
                    'Authorization': `Bearer ${this.refreshToken}`
                }
            });

            this.setTokens(response.data.accessToken, response.data.refreshToken);
        } catch (error) {
            console.error('Token refresh failed', error);
            this.clearTokens();
        }
    }
}

export default new UserStore();