import $api from '../http/index';


export async function authenticateWithInitData(authRequest) {
    try {
      const response = await $api.post('/user/authenticate', authRequest, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Authentication failed:', error);
      throw error;
    }
  }


export async function setCredentials(initData, login, password) {
    try {
        const response = await $api.post('/user/set-credentials', {
            initData,
            login,
            password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Setting credentials failed:', error);
        throw error;
    }
}