import axios from 'axios';
import {api_addr} from './urls';
import userStore from '../stores/userStore';

const getItem = (key, callback) => {
    window.Telegram.WebApp.CloudStorage.getItem(key, (error, value) => {
        if (error === null){
            if (value !== null) {
                callback(value);
            } else {
                console.error(`Значение для токена не найдено`);
                callback(null);
            }
        } else{
            console.error(`Ошибка: ${error}`);
            callback(null);
        }
    });
};
const getItemAsync = (key) => {
    return new Promise((resolve, reject) => {
        getItem(key, (value) => {
            resolve(value);
        });
    });
};
const setItem = (key, value) => {
    window.Telegram.WebApp.CloudStorage.setItem(key, value, (result) => {
        if (result !== null) {
            console.error(`Ошибка при установке токена в хранилище cloudStorage: ${result}`);
        }
    });
};
const $api = axios.create({
    baseURL: api_addr
});
$api.interceptors.request.use(async (config) => {
    if (userStore.accessToken) {
        config.headers.Authorization = `Bearer ${userStore.accessToken}`;
    }
    return config;
});

$api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                await userStore.refreshTokens();
                return $api(originalRequest);
            } catch (error) {
                console.error('Refresh token failed', error);
                userStore.clearTokens();
                throw error;
            }
        }
        return Promise.reject(error);
    }
);

export default $api;