import React, { useState } from 'react';
import { MoreVertical } from 'lucide-react';
import './Menu.scss';
const Menu = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };
  
    return (
        <div className={`menu ${isOpen ? 'open' : ''}`}>
            <div className="menu-button">
                <button className="menu-trigger" onClick={toggleMenu}>
                    <MoreVertical />
                </button>
            </div>
            {isOpen && (
                <div className="menu-content">
                    <ul className="menu-list">
                        {React.Children.map(children, child =>
                            React.cloneElement(child, { closeMenu })
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
  };
  
  const MenuButton = ({ onClick, children, closeMenu }) => {
    const handleClick = () => {
        onClick();
        closeMenu();
    };

    return (
        <li className="menu-item">
            <button className="menu-link" onClick={handleClick}>
                {children}
            </button>
        </li>
    );
};
  
  const MenuSeparator = () => {
    return (
      <div className="menu-separator">
        {/* Содержимое разделителя меню */}
      </div>
    );
  };

export { Menu, MenuButton, MenuSeparator };