import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableHeader, TableBody, TableRow, TableCell} from './Table';
import Select from '../../Elements/Select/Select';
import Input from '../../Elements/Input/Input';
import TextArea from '../../Elements/TextArea/TextArea';
import Button from '../../Elements/Button/Button';
import { Menu, MenuButton, MenuSeparator } from '../../Elements/Menu/Menu';
import managementStore from '../../../stores/managementStore';
import categoriesStore from '../../../stores/categoriesStore';
import './ProductManagementTable.scss';

const PAGE_SIZE = 20;

const ProductManagementTable = observer(() => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [categories, setCategories] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [pendingUpdates, setPendingUpdates] = useState({});
    const [visibleColumns, setVisibleColumns] = useState([
      'article', 
      'categoryName', 
      'name', 
      'producerName', 
      'amount', 
      'price', 
      'color', 
      'size',
      'goodCard',
    ]);
    const [visibleDetailFields, setvisibleDetailFields] = useState([
      'article', 
      'categoryName', 
      'name', 
      'producerName', 
      'amount', 
      'price', 
      'color', 
      'size',
      'goodCard',
    ]);
    const [currentPage, setCurrentPage] = useState(0);
    const tableContainerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await managementStore.fetchProducts();
      const fetchedCategories = await categoriesStore.fetchCategories();
      setCategories(fetchedCategories);
    };
    fetchData();
  }, []);

  const toggleField = (field) => {
    if (visibleDetailFields.includes(field)) {
      setvisibleDetailFields(visibleDetailFields.filter((f) => f !== field));
    } else {
      setvisibleDetailFields([...visibleDetailFields, field]);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setCurrentImageIndex(0);
  };

  const handleFieldChange = (product, field, value) => {

    setPendingUpdates({
      ...pendingUpdates,
      [product.id]: {
        ...pendingUpdates[product.id],
        [field]: value
      }
    });
  };
  const handleSaveChanges = async (product) => {
    const updatedProduct = {
      ...product,
      ...pendingUpdates[product.id]
    };
    await managementStore.updateProduct(product.id, updatedProduct);
    setPendingUpdates({
      ...pendingUpdates,
      [product.id]: undefined
    });
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
    setCurrentPage(0);
  };
  const handleColumnVisibility = (column) => {
    if (visibleColumns.includes(column)) {
      setVisibleColumns(visibleColumns.filter(col => col !== column));
    } else {
      setVisibleColumns([...visibleColumns, column]);
    }
  };
  const sortedProducts = sortColumn
    ? [...managementStore.products].sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      })
    : managementStore.products;

  const paginatedProducts = sortedProducts.slice(
    currentPage * PAGE_SIZE,
    (currentPage + 1) * PAGE_SIZE
  );

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % (selectedProduct?.photoUrls?.length || 0));
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + (selectedProduct?.photoUrls?.length || 0)) % (selectedProduct?.photoUrls?.length || 0));
  };

  const closeDetails = () => {
    setSelectedProduct(null);
  };
  const renderField = (product, field) => {
    console.log(product)
    console.log(field)
    const value = pendingUpdates[product.id]?.[field] ?? product[field];
    if (!visibleDetailFields.includes(product[field])) return null;
    if (field === 'categoryName') {
      return (
        <Select
        value={value}
        onChange={(e) => handleFieldChange(product, field, e.target.value)}
        onBlur={() => handleSaveChanges(product)}
        options={categories}
        optionLabel="name"  // Указываем, что для отображения текста будет использоваться свойство "name"
        optionValue="id"    // Указываем, что значение для <option> будет "id"
       />
      );
    } else if (field === 'description') {
      return (
        <TextArea
          value={value}
          onChange={(e) => handleFieldChange(product, field, e.target.value)}
          onBlur={() => handleSaveChanges(product)}
        />
      );
    } else {
      return (
        <Input
        value={value}
        onChange={(newValue) => handleFieldChange(product, field, newValue)}
        onBlur={() => handleSaveChanges(product)}
        readOnly={false}
        label={visibleFields[field]}
        validationRegex={field === 'amount' || field === 'price' ? /^\d*\.?\d*$/ : null}
        validationMessage={
          (field === 'amount' || field === 'price') && 'Please enter a valid number'
        }
      />
      );
    }
  };

  const visibleFields = [
  { value: 'article', name: 'Артикул' },
  { value: 'categoryName', name: 'Категория' },
  { value: 'name', name: 'Название' },
  { value: 'producerName', name: 'Производитель' },
  { value: 'amount', name: 'Количество' },
  { value: 'price', name: 'Цена' },
  { value: 'color', name: 'Цвет' },
  { value: 'size', name: 'Размер' },
  { value: 'goodCard', name: 'Карточка товара' }
];
  return (
    <div className='board'>
      <div className="product-management-table">
        <div className={`table-container ${selectedProduct ? 'table-compressed' : ''}`} ref={tableContainerRef}>  
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell className="headerColumnsManagerRow">
                    Столбцы
                    <Menu>
                      {visibleFields.map((column) => (
                        <MenuButton
                          key={column.value}
                          onClick={() => handleColumnVisibility(column.value)}
                          className={visibleColumns.includes(column.value) ? 'selected' : ''}
                        >
                          {column.name}
                        </MenuButton>
                      ))}
                      <MenuSeparator />
                      <MenuButton onClick={() => setVisibleColumns(['id', 'article', 'categoryName', 'name', 'producerName', 'amount', 'price', 'color', 'size', 'goodCard'])}>
                        Select All
                      </MenuButton>
                      <MenuButton onClick={() => setVisibleColumns([])}>
                        Deselect All
                      </MenuButton>
                    </Menu>
                </TableCell>
                <TableCell className="headerColumnsManagerRow">
                    Детальное отображение параметров
                    <Menu>
                      {visibleFields.map((column) => (
                        <MenuButton
                          key={column.value}
                          onClick={() => toggleField(column.value)}
                          className={visibleDetailFields.includes(column.value) ? 'selected' : ''}
                        >
                          {column.name}
                        </MenuButton>
                      ))}
                      <MenuSeparator />
                      <MenuButton onClick={() => setvisibleDetailFields(['id', 'article', 'categoryName', 'name', 'producerName', 'amount', 'price', 'color', 'size', 'goodCard'])}>
                        Select All
                      </MenuButton>
                      <MenuButton onClick={() => setvisibleDetailFields([])}>
                        Deselect All
                      </MenuButton>
                    </Menu>
                </TableCell>
              </TableRow>
              <TableRow>
                  {visibleColumns.map((column) => (
                    <TableCell key={column} className="table-cell">
                    <div className="column-header">
                        {column}
                        <Menu>
                            <MenuButton onClick={() => handleSort(column, 'asc')}>
                                Sort Ascending
                            </MenuButton>
                            <MenuButton onClick={() => handleSort(column, 'desc')}>
                                Sort Descending
                            </MenuButton>
                            <MenuSeparator />
                            <MenuButton onClick={() => handleColumnVisibility(column)}>
                                Hide Column
                            </MenuButton>
                        </Menu>
                    </div>
                </TableCell>
                  ))}
                </TableRow>
            </TableHeader>
            <TableBody>
            {paginatedProducts.map((product) => (
                <TableRow
                  key={product.id}
                  className={selectedProduct?.id === product.id ? 'selected' : ''}
                  onClick={() => handleProductSelect(product)}
                >
                  {visibleColumns.map((column) => (
                    <TableCell key={`${product.id}-${column}`}>
                      {column === 'categoryName' ? (
                        <Select
                        value={product.categoryName}
                        onChange={(e) => handleFieldChange(product, 'categoryName', e.target.value)}
                        onBlur={() => handleSaveChanges(product)}
                        options={categories}
                        optionLabel="name"  // Указываем, что для отображения текста будет использоваться свойство "name"
                        optionValue="id"    // Указываем, что значение для <option> будет "id"
                      />
                      ) : (
                        <Input
                          value={product[column]}
                          onChange={(newValue) => handleFieldChange(product, column, newValue)}
                          onBlur={() => handleSaveChanges(product)}
                          readOnly={false}
                          validationRegex={column === 'amount' || column === 'price' ? /^\d*\.?\d*$/ : null}
                          validationMessage={
                            (column === 'amount' || column === 'price') && 'Please enter a valid number'
                          }
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <div className="pagination">
          <Button
            onClick={prevPage}
            disabled={currentPage === 0}
            className={`pagination-button ${currentPage === 0 ? 'disabled' : ''}`}
          >
            Prev
          </Button>
          <div className="pagination-info">
            Page {currentPage + 1} of {Math.ceil(sortedProducts.length / PAGE_SIZE)}
          </div>
          <Button
            onClick={nextPage}
            disabled={paginatedProducts.length < PAGE_SIZE}
            className={`pagination-button ${paginatedProducts.length < PAGE_SIZE ? 'disabled' : ''}`}
          >
            Next
          </Button>
        </div>
      </div>
      {selectedProduct && (
          <div className="details-container">
            <Button className="close-button" onClick={closeDetails}>✖</Button>
            <div className="image-slider">
              <Button onClick={prevImage} disabled={selectedProduct.photoUrls.length <= 1}>←</Button>
              <img src={selectedProduct.photoUrls[currentImageIndex]} alt="Product" />
              <Button onClick={nextImage} disabled={selectedProduct.photoUrls.length <= 1}>→</Button>
            </div>
            <div className="productDetailsPanel">
              {Object.keys(visibleFields).map((field) => (
                <p key={field}>
                  {renderField(selectedProduct, field)}
                </p>
              ))}
            </div>
          </div>
        )}
    </div>
  );
});

export default ProductManagementTable;