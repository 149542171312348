import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ordersStore from '../../../stores/ordersStore';
import './Orders.css';

const Orders = observer(() => {
    const [expandedOrderId, setExpandedOrderId] = useState(null);

    useEffect(() => {
        ordersStore.fetchOrders();
    }, []);

    const handleOrderClick = (orderId) => {
        if (expandedOrderId === orderId) {
            setExpandedOrderId(null);
        } else {
            setExpandedOrderId(orderId);
            ordersStore.fetchOrderDetails(orderId);
        }
    };

    const handleStatusChange = (orderId, field) => {
        ordersStore.updateOrderStatus(orderId, field);
    };

    const handleDeleteOrder = (orderId) => {
        if (window.confirm('Вы уверены, что хотите удалить этот заказ?')) {
            ordersStore.deleteOrder(orderId);
        }
    };

    return (
        <div className="orders-container">
            <h2>Заказы</h2>
            <table className="orders-table">
                <thead>
                    <tr>
                        <th>№ заказа</th>
                        <th>ID клиента</th>
                        <th>Дата создания</th>
                        <th>Подтвержден</th>
                        <th>Выполнен</th>
                        <th>Сумма</th>
                        <th>Действия</th>
                    </tr>
                </thead>
                <tbody>
                    {ordersStore.orders.map(order => (
                        <React.Fragment key={order.Id}>
                            <tr onClick={() => handleOrderClick(order.Id)}>
                                <td>{order.Id}</td>
                                <td>{order.ClientId}</td>
                                <td>{new Date(order.CreatedAt).toLocaleString()}</td>
                                <td>
                                    <input 
                                        type="checkbox" 
                                        checked={order.IsApproved} 
                                        onChange={() => handleStatusChange(order.Id, 'IsApproved')}
                                    />
                                </td>
                                <td>
                                    <input 
                                        type="checkbox" 
                                        checked={order.IsCompleted} 
                                        onChange={() => handleStatusChange(order.Id, 'IsCompleted')}
                                    />
                                </td>
                                <td>{order.OrderPositions.reduce((sum, pos) => sum + pos.Amount, 0)}</td>
                                <td>
                                    <button onClick={() => handleDeleteOrder(order.Id)}>Удалить</button>
                                </td>
                            </tr>
                            {expandedOrderId === order.Id && (
                                <tr>
                                    <td colSpan="7">
                                        <table className="order-details">
                                            <thead>
                                                <tr>
                                                    <th>Артикул</th>
                                                    <th>Название товара</th>
                                                    <th>Количество</th>
                                                    <th>Ссылка</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.OrderPositions.map(position => (
                                                    <tr key={position.Id}>
                                                        <td>{position.Article}</td>
                                                        <td>{position.GoodName}</td>
                                                        <td>{position.Amount}</td>
                                                        <td><a href={position.Link} target="_blank" rel="noopener noreferrer">Ссылка</a></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
});

export default Orders;
