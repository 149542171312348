import React,  { useState, useCallback }  from 'react';
import './Input.scss';

const Input = ({
  value,
  onChange,
  onBlur,
  readOnly,
  label,
  validationRegex = null,
  validationMessage = null,
}) => {
  const [isShaking, setIsShaking] = useState(false);

  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value;

      if (validationRegex && !validationRegex.test(newValue)) {
        // Если введен неправильный символ - показываем анимацию тряски
        setIsShaking(true);
        setTimeout(() => setIsShaking(false), 500);
        return;
      }

      onChange(newValue);
    },
    [onChange, validationRegex]
  );

  return (
    <div
      className={`input-container ${isShaking ? 'shake' : ''}`}
      style={{
        backgroundColor: 'var(--secondary-bg-color)',
        borderColor: 'var(--hint-color)'
      }}
    >
      <div className="input-wrapper">
        {label && (
          <label
            className="input-label"
            style={{ color: 'var(--text-color)' }}
          >
            {label}
          </label>
        )}
        <div className="input-field-wrapper">
          <input
            className={`input-field ${readOnly ? 'input-readonly' : ''}`}
            style={{
              color: 'var(--text-color)'
            }}
            type="text"
            value={value}
            onChange={handleInputChange}
            onBlur={onBlur}
            readOnly={readOnly}
          />
          {validationMessage && !validationRegex.test(value) && (
            <div
              className="input-validation-message"
              style={{ color: 'var(----destructive-text-color)' }}
            >
              {validationMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


export default Input;
