import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import dashboardStore from '../../../stores/dashboardStore';
import './Dashboard.css';

const Dashboard = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dashboardStore.fetchDashboardData();
            } catch (error) {
                console.error('Ошибка при загрузке данных панели управления:', error);
                setError('Не удалось загрузить данные. Попробуйте позже.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    
    const handleQuickAction = (action) => {
        switch (action) {
            case 'newProduct':
                navigate('/management?action=new');
                break;
            case 'allOrders':
                navigate('/orders');
                break;
            default:
                break;
        }
    };

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="dashboard-container">
            <h1>Панель управления</h1>
            <div className="dashboard-summary">
                <div className="summary-item">
                    <h3>Всего товаров</h3>
                    <p>{dashboardStore.totalProducts}</p>
                </div>
                <div className="summary-item">
                    <h3>Активные заказы</h3>
                    <p>{dashboardStore.activeOrders}</p>
                </div>
                <div className="summary-item">
                    <h3>Продажи за месяц</h3>
                    <p>{dashboardStore.monthlySales} ₽</p>
                </div>
                <div className="summary-item">
                    <h3>Новые пользователи</h3>
                    <p>{dashboardStore.newUsers}</p>
                </div>
            </div>

            <div className="dashboard-chart">
                <h2>График продаж</h2>
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart data={dashboardStore.salesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="sales" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div className="dashboard-top-products">
                <h2>Топ продуктов</h2>
                <ul>
                    {dashboardStore.topProducts.map(product => (
                        <li key={product.id}>{product.name} - {product.sales} продаж</li>
                    ))}
                </ul>
            </div>

            <div className="dashboard-recent-orders">
                <h2>Последние заказы</h2>
                <ul>
                    {dashboardStore.recentOrders.map(order => (
                        <li key={order.id}>
                            №{order.id} - {new Date(order.date).toLocaleDateString()} - {order.total} ₽ - {order.status}
                        </li>
                    ))}
                </ul>
            </div>

            <div className="dashboard-notifications">
                <h2>Уведомления</h2>
                <ul>
                    {dashboardStore.notifications.map((notification, index) => (
                        <li key={index}>{notification}</li>
                    ))}
                </ul>
            </div>

            <div className="dashboard-quick-actions">
                <h2>Быстрые действия</h2>
                <button onClick={() => handleQuickAction('newProduct')}>Создать товар</button>
                <button onClick={() => handleQuickAction('allOrders')}>Все заказы</button>
            </div>
        </div>
    );
});

export default Dashboard;