import React from 'react';
import './Button.scss';

const Button = ({ onClick, disabled, className, children }) => {
  return (
    <button
      className={`btn ${disabled ? 'btn-disabled' : ''} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
