import React from 'react';
import './Select.scss';

const Select = ({ value, onChange, onBlur, options, optionLabel, optionValue }) => {
  return (
    <select className="select" value={value} onChange={onChange} onBlur={onBlur}>
      {options.map((option) => (
        <option key={option[optionValue]} value={option[optionValue]}>
          {option[optionLabel]}
        </option>
      ))}
    </select>
  );
};

export default Select;
